<template>
  <div v-if="order">
    <b-row>
      <b-col>
        <b-card style="width: 300px; position: absolute; right: 29px; top: -30px"
          >ORDER ID
          <b-card class="bg-danger position-absolute text-white" style="right: 0; top: 0"
            ><b>{{ order.reference_id }}</b></b-card
          ></b-card
        >
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <!--GrandTotal-->
        <b-card>
          <b-row>
            <b-col cols="1 ml-1">
              <feather-icon style="color: red" icon="DollarSignIcon" size="25" />
            </b-col>
            <b-col cols="4">
              <b>Grand Total</b>
              <p>{{ $helpers.formatTotal(order.price) }}</p>
            </b-col>
          </b-row>
        </b-card>
        <!--Order Date/Delivery Date-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="AirplayIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>ORDER DATE</b>
                  <p>{{ $helpers.formatDate(order.date_added) }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="CalendarIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>DELIVERY DATE</b> <br />
                  <p>{{ $helpers.formatDateTime(order.delivery_date) }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--PO-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="FolderMinusIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>PO NUMBER</b>
                  <p>{{ order.po_number }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="FolderMinusIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>PO EXPIRY DATE</b>
                  <p>{{ $helpers.formatDate(order.po_expiry_date) }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--Sender/Recepient-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="HomeIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>SENDER NAME</b> <br />
                  <p>{{ order.sender ? order.sender.name : '-' }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="HomeIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>RECIPIENT NAME</b> <br />
                  <p>{{ order.recipient ? order.recipient.name : '-' }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--Sender Address/Recepient Address-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="MapPinIcon" size="25" />
                </b-col>
                <b-col cols="8">
                  <b>SENDER ADDRESS</b> <br />
                  <p>
                    {{ order.sender ? order.sender.complete : '-' }}
                  </p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="MapPinIcon" size="25" />
                </b-col>
                <b-col cols="8">
                  <b>RECIPIENT ADDRESS</b> <br />
                  <p>
                    {{ order.recipient ? order.recipient.complete : '-' }}
                  </p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--Sender Phone/Recepient Phone-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="PhoneIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>SENDER PHONE NUMBER</b>
                  <p>{{ order.sender ? order.sender.phone : '-' }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="2 ml-1">
                  <feather-icon style="color: red" icon="PhoneIcon" size="25" />
                </b-col>
                <b-col cols="4">
                  <b>RECIPIENT PHONE NUMBER</b>
                  <p>{{ order.recipient ? order.recipient.phone : '-' }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--Product-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="1 ml-1">
                  <feather-icon style="color: red" icon="BoxIcon" size="25" />
                </b-col>
                <b-col cols="10">
                  <b>PRODUCT</b>
                  <table class="table table-bordered">
                    <tr>
                      <th>Quantity</th>
                      <th>Category</th>
                      <th>Packaging</th>
                      <th>Size</th>
                      <th>Temperature</th>
                      <th>SKU</th>
                    </tr>
                    <tr>
                      <td style="height: 100px">
                        {{ order.quantity }}
                      </td>
                      <td>{{ order.category.text }}</td>
                      <td>{{ order.packaging.text }}</td>
                      <td>{{ order.size }} {{ order.unit }}</td>
                      <td>{{ order.temperature }} °C</td>
                      <td>{{ order.sku }}</td>
                    </tr>
                  </table>
                  <table class="table table-bordered mt-2">
                    <tr>
                      <th class="text-center">REMARKS</th>
                    </tr>
                    <tr>
                      <td style="height: 100px; text-align: center">
                        {{ order.remarks }}
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="1 ml-1">
                  <feather-icon style="color: red" icon="DownloadIcon" size="25" />
                </b-col>
                <b-col cols="10">
                  <a href="#" @click.prevent="handleDownloadFile('do', orderId)"
                    >Download Delivery Order</a
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="1 ml-1">
                  <feather-icon style="color: red" icon="DownloadIcon" size="25" />
                </b-col>
                <b-col cols="10">
                  <a href="#" @click.prevent="downloadInvoice()">Download Invoice</a>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--Smarttruck-->
        <b-row v-if="order.smarttruck_name">
          <b-col>
            <b-card>
              <b-row>
                <b-col cols="1 ml-1">
                  <feather-icon style="color: red" icon="TruckIcon" size="25" />
                </b-col>
                <b-col cols="10">
                  <b>SMARTTRUCK</b>

                  <table class="table table-bordered">
                    <tr>
                      <th>Vehicle Plate Number</th>
                      <th>Driver's Name</th>
                      <th>Vehicle Model</th>
                      <th>Vehicle Make</th>
                    </tr>
                    <tr>
                      <td style="height: 100px" class="text-uppercase">
                        {{ order.vehivle.plate }}
                      </td>
                      <td>{{ order.driver.full_name }}</td>
                      <td>{{ order.vehicle.model }}</td>
                      <td>{{ order.vehicle.make }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--Back Button-->
    <b-row>
      <b-col class="text-center"
        ><b-button type="button" variant="primary" class="width-250" @click="$router.go(-1)">
          Back
        </b-button></b-col
      >
    </b-row>
    <div v-if="downloadInvoiceD" hidden>
      <Invoice :order="order" />
    </div>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { downloadOrderFile } from '@/mixins/download-order-file'
import Invoice from '@/components/Invoice.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@axios'

export default {
  components: {
    FeatherIcon,
    Invoice,
  },
  mixins: [downloadOrderFile],
  data() {
    return {
      user: { name: 'Name' },
      isLoading: false,
      downloadInvoiceD: false,
      order: null,
      orderId: null,
      details: {
        type: Object,
        default: () => {},
      },
    }
  },
  created() {
    this.orderId = this.$route.params.id
    this.getOrderDetails(this.$route.params.id)
  },
  methods: {
    downloadInvoice() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Downloading invoice...",
          icon: 'DownloadIcon',
          variant: 'success',
        },
      })
      this.downloadInvoiceD = true
    },
    async getOrderDetails(orderId) {
      this.isLoading = true
      const response = await axios.get(`/orders/${orderId}`)
      if (response.status === 200) {
        this.order = response.data.data
      }
      this.isLoading = false
    },
  },
}
</script>
<style scoped></style>
